@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-primary: #225378;
    --color-secondary: #c0779d;
    --color-tertiary: #ffffff;

    --sizing-min-width: 320px;
    --sizing-max-width: 1200px;
}

body {
    color: var(--color-primary);
    min-width: var(--sizing-min-width);
    max-width: var(--sizing-max-width);
    margin: auto;
}

.--no-scroll {
    overflow: hidden;
}

img .-lg {
    width: 100%;
    height: auto;
}

.svg-primary {
    fill: var(--color-primary);
    stroke: var(--color-primary)
}

.svg-secondary {
    fill: var(--color-secondary);
    stroke: var(--color-secondary)
}

.svg-tertiary {
    fill: var(--color-tertiary);
    stroke: var(--color-tertiary)
}

a, button:not([disabled]):focus {

}

